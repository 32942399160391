
/* eslint max-classes-per-file: ["error", 2] */

import { Options, Vue, prop } from "vue-class-component";
import { PropType } from "vue";
import { MenuItem } from "../store/modules/settings/types";

class Props {
  menuItems = prop({
    type: Object as PropType<MenuItem[]>,
    required: true,
  });
}

@Options({})
export default class NavItems extends Vue.with(Props) {}
