import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "normalize.css";

if (process.env.NODE_ENV === "development") {
  /* eslint-disable global-require */
  /* eslint-disable  @typescript-eslint/no-var-requires */
  const worker = require("./mocks/browser");
  /* eslint-enable global-require */
  worker.default.start();
}

createApp(App).use(store).use(router).mount("#app");
