import { createStore, StoreOptions } from "vuex";
import DataFromApi from "@/api";
import settings from "./modules/settings";
import additions from "./modules/additions";
import sections from "./modules/sections";
import meals from "./modules/meals";
import basket from "./modules/basket";
import order from "./modules/order";
import { RootState, RootMutations } from "./types";

const rootState: RootState = {
  displayedMealId: 0,
  api: new DataFromApi(),
};

const rootMutations: RootMutations = {
  changeDisplayedMealId(state, mealId) {
    state.displayedMealId = mealId;
  },
};

const store: StoreOptions<RootState> = {
  state: rootState,
  getters: {},
  mutations: rootMutations,
  actions: {},
  modules: {
    settings,
    sections,
    additions,
    meals,
    basket,
    order,
  },
  strict: process.env.NODE_ENV !== "production",
};

export default createStore(store);
