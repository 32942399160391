import { Module } from "vuex";
import LocalStorage from "@/localStorage";
import { BasketFullInfoItem, BasketItem, BasketItemToApi } from "@/interfaces/Basket";
import { RootState } from "@/store/types";
import { MealFullData } from "@/interfaces/Meal";
import { AdditionData, AdditionsInMeal } from "@/interfaces/Addition";
import { BState, BGetters, BMutations, BActions } from "./types";

const localStorageObject = new LocalStorage();

const basketState: BState = { basket: [] };

export const getters: BGetters = {
  getBasketItemCost: (moduleState, basketGetters, rootState, rootGetters) => (basketItem) => {
    const count: number = basketItem.count > 0 ? basketItem.count : 1;
    let currBasketItemCost: number =
      rootGetters["meals/oneMealCost"](basketItem.mealId) +
      rootGetters["additions/getAdditionsCost"](basketItem.additions);

    currBasketItemCost *= count;

    return currBasketItemCost;
  },
  basketCost(moduleState, basketGetters): number {
    let result = 0;

    result = moduleState.basket.reduce<number>(
      (prevBasketItem: number, currBasketItem: BasketItem) => {
        const getterName = "getBasketItemCost";
        const currBasketItemCost = basketGetters[getterName](currBasketItem);

        return prevBasketItem + currBasketItemCost;
      },
      result
    );

    return result;
  },
  getBasketFullInfo(moduleState, basketGetters, rootState, rootGetters) {
    const result: BasketFullInfoItem[] = [];

    moduleState.basket.forEach((item: BasketItem) => {
      const mealData: MealFullData | false = rootGetters["meals/getMealDataById"](item.mealId);

      if (mealData) {
        const itemInfo: BasketFullInfoItem = {
          ...item,
          mealData,
          additionsData: {},
        };

        itemInfo.additions = { ...item.additions };

        Object.keys(item.additions).forEach((key: string) => {
          const addData: AdditionData = rootGetters["additions/getAdditionDataById"](+key);

          if (addData) {
            itemInfo.additionsData[key] = addData;
          }
        });

        result.push(itemInfo);
      }
    });

    return result;
  },

  basketForOrder(state) {
    return state.basket.map((bItem) => {
      const { mealId, count } = bItem;

      const additions = Object.keys(bItem.additions).map((key) => ({
        Id: +key,
        count: bItem.additions[key],
      }));

      const result: BasketItemToApi = {
        mealId,
        count,
        additions,
      };

      return result;
    });
  },
};

const mutations: BMutations = {
  addBasketItem(state, data) {
    const result: BasketItem = {
      id: data.id,
      mealId: data.mealId,
      count: data.count,
      additions: {},
    };
    Object.keys(data.additions).forEach((additId: string) => {
      if (data.additions[additId]) {
        result.additions[additId] = data.additions[additId];
      }
    });
    state.basket.push(result);

    localStorageObject.saveItem(state.basket);
  },

  updateBasketItem(state, data) {
    const basketItemKey = state.basket.findIndex((item) => item.id === data.id);

    const notEmptyAdditions: AdditionsInMeal = {};
    Object.keys(data.additions).forEach((additId: string) => {
      if (data.additions[additId]) {
        notEmptyAdditions[additId] = data.additions[additId];
      }
    });

    state.basket[basketItemKey] = {
      id: data.id,
      mealId: data.mealId,
      count: data.count,
      additions: { ...notEmptyAdditions },
    };

    localStorageObject.saveItem(state.basket);
  },

  deleteBasketItem(state, itemId) {
    state.basket.splice(
      state.basket.findIndex((item) => item.id === itemId),
      1
    );

    localStorageObject.saveItem(state.basket);
  },

  clearBasket(state) {
    state.basket = [];
    localStorageObject.deleteItem();
  },
};

const actions: BActions = {
  getBasketFromLocalStorage({ commit }) {
    const data: BasketItem[] = localStorageObject.getItem();
    if (data) {
      data.forEach((element: BasketItem) => {
        commit("addBasketItem", element);
      });
    }
  },

  saveBasketToLocalStorage({ state }) {
    localStorageObject.saveItem(state.basket);
  },
};

const basket: Module<BState, RootState> = {
  namespaced: true,
  state: basketState,
  getters,
  mutations,
  actions,
  modules: {},
};

export default basket;
