import { Module } from "vuex";
import { Meal, MealData, MealFullData, MealId, MealImgsUrls } from "@/interfaces/Meal";
import { RootState } from "@/store/types";
import { MealState, MealMutations, MealActions, MealGetters } from "./types";

const mealState: MealState = { arMeals: {} };

export const getters: MealGetters = {
  oneMealCost: (state) => (mealId) => state.arMeals[mealId].price,
  getMealDataById: (state, mGetters, rootState) => (mealId) => {
    if (!state.arMeals[mealId]) {
      return false;
    }

    const mealImgsUrls: MealImgsUrls = {
      menuImgUrl: rootState.api.getMealImgUrl(state.arMeals[mealId].menuImgFileName),
      orderImgUrl: rootState.api.getMealImgUrl(state.arMeals[mealId].orderImgFileName),
      largeImgUrl: rootState.api.getMealImgUrl(state.arMeals[mealId].largeImgFileName),
      smallImgUrl: rootState.api.getMealImgUrl(state.arMeals[mealId].smallImgFileName),
    };

    const mealData = { ...state.arMeals[mealId], ...mealImgsUrls };

    return mealData;
  },
  getMealIdsBySectionId: (state) => (sectionId) => {
    const result = Object.keys(state.arMeals)
      .filter((key) => +state.arMeals[key].sectionId === +sectionId)
      .map((id) => +id);
    return result;
  },
  getMealsBySectionId: (state, mGetters) => (sectionId) => {
    const result: { [key: string]: MealFullData } = {};
    const mealIds: MealId[] = mGetters.getMealIdsBySectionId(sectionId);
    mealIds.forEach((mealId) => {
      result[+mealId] = mGetters.getMealDataById(+mealId);
    });
    return result;
  },
};

const mutations: MealMutations = {
  addMeal(state, data): void {
    const result: MealData = {
      name: data.name,
      description: data.description,
      menuImgFileName: data.menuImgFileName,
      orderImgFileName: data.orderImgFileName,
      largeImgFileName: data.largeImgFileName,
      smallImgFileName: data.smallImgFileName,
      price: data.price,
      sectionId: data.sectionId,
    };
    result.additions = data.additions ? [...data.additions] : [];
    state.arMeals[data.id] = result;
  },
};

const actions: MealActions = {
  async getMealList({ commit, rootState }) {
    const data: Meal[] = await rootState.api.getMealList();
    if (data) {
      data.map((value: Meal) => {
        commit("addMeal", value);
        return true;
      });
    }
  },
};

const meals: Module<MealState, RootState> = {
  namespaced: true,
  state: mealState,
  getters,
  mutations,
  actions,
  modules: {},
};
export default meals;
