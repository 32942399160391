import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, ind) => {
      return (_openBlock(), _createElementBlock("li", { key: ind }, [
        _createVNode(_component_router_link, {
          to: {
          name: item.routeName,
          hash: '#' + item.link,
        }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.title), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ]))
}