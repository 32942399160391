import { BasketItem } from "@/interfaces/Basket";
import { Storage } from "./interface";

export default class LocalStorage implements Storage {
  readonly keyName = "basket";

  getItem(): BasketItem[] {
    let result: BasketItem[] = [];

    const basket = localStorage.getItem(this.keyName);
    if (basket) {
      result = JSON.parse(basket);
    }

    // TODO: if result not BasketItem[]

    return result;
  }

  saveItem(data: BasketItem[]): void {
    try {
      localStorage.setItem(this.keyName, JSON.stringify(data));
    } catch (error) {
      // TODO: if the storage is full
      // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#exceptions
    }
  }

  deleteItem(): void {
    localStorage.removeItem(this.keyName);
  }
}
