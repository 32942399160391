
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapMutations, mapState } from "vuex";

import { MealId, Meal, MealFullData } from "../interfaces/Meal";
import { AdditionsInMeal, AdditionId, AdditionData } from "../interfaces/Addition";
import { SectionId, SectionData } from "../interfaces/Section";
import { BasketItem } from "../interfaces/Basket";

@Options({
  computed: {
    ...mapState(["displayedMealId"]),
    ...mapState("meals", ["arMeals"]),
    ...mapGetters("meals", ["getMealDataById"]),
    ...mapGetters("additions", ["getAdditionDataById", "getAdditionsCost"]),
    ...mapGetters("sections", ["getSectionDataById"]),
    ...mapGetters("basket", ["getBasketItemCost"]),
  },
  methods: {
    ...mapMutations(["changeDisplayedMealId"]),
    ...mapMutations("basket", ["addBasketItem"]),
  },
})
export default class CardPage extends Vue {
  displayedMealId!: MealId;

  mealData: MealFullData | false = false;

  arMeals!: Meal[];

  basketItem: BasketItem = {
    id: 0,
    mealId: 0,
    count: 1,
    additions: {},
  };

  getMealDataById!: (mealId: MealId) => MealFullData | false;

  changeDisplayedMealId!: (mealId: MealId) => void;

  getAdditionDataById!: (id: AdditionId) => AdditionData;

  getSectionDataById!: (id: SectionId) => SectionData;

  getAdditionsCost!: (data: AdditionsInMeal) => number;

  getBasketItemCost!: (basketItem: BasketItem) => number;

  addBasketItem!: (data: BasketItem) => void;

  changeAdditionCount(additionId: number, count: number): void {
    this.basketItem.additions[additionId.toString()] = count;
  }

  addToBasket(): void {
    this.addBasketItem(this.basketItem);
    this.changeDisplayedMealId(0);
  }

  beforeMount(): void {
    this.basketItem = {
      id: new Date().getTime(),
      mealId: this.displayedMealId,
      count: 1,
      additions: {},
    };
    this.mealData = this.getMealDataById(this.displayedMealId);
    if (this.mealData && this.mealData.additions) {
      const additionsHash: { [key: string]: number } = {};
      this.mealData.additions.forEach((additId) => {
        additionsHash[`${additId}`] = 0;
      });
      this.basketItem.additions = additionsHash;
    }
  }
}
