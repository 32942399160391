import { Module } from "vuex";
import { Section } from "@/interfaces/Section";
import { RootState } from "@/store/types";
import { SectionState, SectionMutations, SectionActions, SectionGetters } from "./types";
import { MenuItem } from "../settings/types";

const stateData: SectionState = { arSections: {} };

const getters: SectionGetters = {
  getSectionDataById: (state) => (id) => state.arSections[id],
  getAllSections: (state) => state.arSections,
  sectionsToMenuItems: (state) => {
    const result: MenuItem[] = Object.keys(state.arSections).map((key) => {
      const item: MenuItem = {
        title: state.arSections[key].name,
        link: state.arSections[key].url,
        routeName: "Home",
      };
      return item;
    });

    return result;
  },
};

const mutations: SectionMutations = {
  addSection(state, data) {
    state.arSections[data.id] = {
      name: data.name,
      url: data.url,
    };
  },
};

const actions: SectionActions = {
  async getSectionList({ commit, rootState }) {
    const data: Section[] = await rootState.api.getSectionList();
    if (data) {
      data.forEach((value: Section) => {
        commit("addSection", value);
      });
    }
  },
};

const sections: Module<SectionState, RootState> = {
  namespaced: true,
  state: stateData,
  getters,
  mutations,
  actions,
  modules: {},
};
export default sections;
