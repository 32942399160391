import { Module } from "vuex";
import { RootState } from "@/store/types";
import { SettingsGetters, SettingsState } from "./types";

const settingsState: SettingsState = {
  workTime: {
    start: {
      hour: "09",
      minute: "00",
    },
    finish: {
      hour: "22",
      minute: "00",
    },
  },
  phone: {
    title: "+7 903 409-14-09",
    link: "",
  },
  social: {
    instagram: {
      title: "Mr.B.A.G - Instagram",
      link: "https://www.instagram.com/",
    },
  },
  info: {
    oferta: {
      link: "",
      title: "Публичная оферта",
      routeName: "Oferta",
    },
    policy: {
      link: "",
      title: "Политика конфедициальности",
      routeName: "Policy",
    },
    protect: {
      link: "",
      title: "2021 Mr.B.A.G Все права защищены",
      routeName: "",
    },
  },
  mainMenuItems: [
    {
      title: "Акции",
      link: "action",
      routeName: "About",
    },
    {
      title: "О нас",
      link: "about-us",
      routeName: "About",
    },
    {
      title: "Доставка и оплата",
      link: "delivery",
      routeName: "About",
    },
    {
      title: "Контакты",
      link: "contacts",
      routeName: "About",
    },
  ],
};

export const getters: SettingsGetters = {
  startWorkTimeOnDate: (state) => (date) => {
    const todayData: Date = date ? new Date(date) : new Date();
    todayData.setHours(+state.workTime.start.hour, +state.workTime.start.minute, 0);

    return todayData;
  },
  finishWorkTimeOnDate: (state) => (date) => {
    const todayData: Date = date ? new Date(date) : new Date();
    todayData.setHours(+state.workTime.finish.hour, +state.workTime.finish.minute, 0);

    return todayData;
  },
};

const settings: Module<SettingsState, RootState> = {
  namespaced: true,
  state: settingsState,
  getters,
};

export default settings;
