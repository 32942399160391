
import { Options, Vue } from "vue-class-component";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

import { MealId } from "./interfaces/Meal";
import {
  infoKeys,
  MenuItem,
  MenuItemExternal,
  socialKeys,
  WorkTimeData,
} from "./store/modules/settings/types";
import CardPage from "./components/CardPage.vue";
import NavItems from "./components/NavItems.vue";

@Options({
  components: {
    CardPage,
    NavItems,
  },
  computed: {
    ...mapState(["displayedMealId"]),
    ...mapState("settings", ["mainMenuItems", "info", "phone", "social", "workTime"]),
    ...mapGetters("sections", ["sectionsToMenuItems"]),
    ...mapGetters("basket", ["basketCost"]),
  },
  methods: {
    ...mapMutations(["changeDisplayedMealId"]),
    ...mapActions("sections", ["getSectionList"]),
    ...mapActions("additions", ["getAdditionList"]),
    ...mapActions("meals", ["getMealList"]),
    ...mapActions("basket", ["getBasketFromLocalStorage"]),
  },
})
export default class App extends Vue {
  showMobileMenu = false;

  displayedMealId!: MealId;

  info!: Record<infoKeys, MenuItem>;

  phone!: MenuItemExternal;

  social!: Partial<Record<socialKeys, MenuItemExternal>>;

  workTime!: {
    start: WorkTimeData;
    finish: WorkTimeData;
  };

  mainMenuItems!: MenuItem[];

  sectionsToMenuItems!: MenuItem[];

  basketCost!: number;

  getSectionList!: () => Promise<void>;

  getAdditionList!: () => Promise<void>;

  getMealList!: () => Promise<void>;

  changeDisplayedMealId!: (mealId: MealId) => void;

  getBasketFromLocalStorage!: () => void;

  get showMealCardPage(): boolean {
    return this.displayedMealId > 0;
  }

  async created(): Promise<void> {
    try {
      await this.getSectionList();
      await this.getAdditionList();
      await this.getMealList();
    } catch (error) {
      console.log(error);
    }
    this.getBasketFromLocalStorage();
  }
}
