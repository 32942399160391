import { Module } from "vuex";
import { Addition, AdditionData } from "@/interfaces/Addition";
import { RootState } from "@/store/types";
import { AdditionState, AdditionGetters, AdditionMutations, AdditionActions } from "./types";

const additionState: AdditionState = { arAdditions: {} };

export const getters: AdditionGetters = {
  getAdditionPrice: (state) => (id) => {
    const additionPrice = state.arAdditions[id] ? state.arAdditions[id].price : false;
    return additionPrice;
  },
  getAdditionDataById: (state, aGetters) => (id) => {
    if (!state.arAdditions[id]) {
      return false;
    }

    const getterName = "getAdditionPrice";

    const result: AdditionData = {
      name: state.arAdditions[id].name,
      price: aGetters[getterName](id),
    };

    return result;
  },
  getAdditionsCost: (state, aGetters) => (data) => {
    let summ = 0;

    Object.keys(data).forEach((key: string) => {
      const getterName = "getAdditionDataById";
      const additionData = aGetters[getterName](+key);
      summ += additionData ? additionData.price * data[key] : 0;
    });

    return summ;
  },
};

const mutations: AdditionMutations = {
  addAddition(state, data) {
    state.arAdditions[data.id] = {
      name: data.name,
      price: data.price,
    };
  },
};

const actions: AdditionActions = {
  async getAdditionList({ commit, rootState }) {
    const data: Addition[] = await rootState.api.getAdditionList();
    if (data) {
      data.forEach((value: Addition) => {
        commit("addAddition", value);
      });
    }
  },
};

const additions: Module<AdditionState, RootState> = {
  namespaced: true,
  state: additionState,
  getters,
  mutations,
  actions,
  modules: {},
};

export default additions;
