import { Section } from "@/interfaces/Section";
import { Addition } from "@/interfaces/Addition";
import { Meal } from "@/interfaces/Meal";
import { Order, OrderAnswerFromApi } from "@/interfaces/Order";
import { API } from "./types";

export default class DataFromApi implements API {
  apiUrl: string;

  constructor(apiUrl = "") {
    this.apiUrl = apiUrl;
  }

  async getSectionList(): Promise<Section[]> {
    const response = await fetch(`${this.apiUrl}/api/active-sections`);
    const data: Section[] = await response.json();
    return data;
  }

  async getAdditionList(): Promise<Addition[]> {
    const response = await fetch(`${this.apiUrl}/api/active-additions`);
    const data: Addition[] = await response.json();
    return data;
  }

  async getMealList(): Promise<Meal[]> {
    const response = await fetch(`${this.apiUrl}/api/active-meals`);
    const data: Meal[] = await response.json();
    return data;
  }

  getMealImgUrl(imgFileName: string): string {
    return `${this.apiUrl}/api/meals/image/${imgFileName}`;
  }

  async sendOrder(orderData: Order): Promise<OrderAnswerFromApi> {
    const response = await fetch(`${this.apiUrl}/api/orders`, {
      method: "POST",
      body: JSON.stringify(orderData),
      headers: { "Content-Type": "application/json" },
    });
    const data: OrderAnswerFromApi = await response.json();
    return data;
  }
}
