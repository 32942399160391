import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/basket/",
    name: "Basket",
    component: () => import("@/views/Basket.vue"),
  },
  {
    path: "/ordering/",
    name: "Ordering",
    component: () => import("@/views/Ordering.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/About.vue"),
  },
  {
    path: "/oferta",
    name: "Oferta",
    component: () => import("@/views/Oferta.vue"),
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import("@/views/Policy.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const el = document.getElementById(to.hash.slice(1));
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  },
});

export default router;
