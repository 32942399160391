import { Module } from "vuex";
import { RootState } from "@/store/types";
import {
  DeliveryTypeItem,
  DeliveryTypesForComponent,
  SendOrderToApiResult,
  ValidatorResult,
} from "@/interfaces/Order";
import { OrderActions, OrderGetters, OrderMutations, OrderState } from "./types";

const orderState: OrderState = {
  defaultDeliveryType: {
    id: 0,
    name: "Самовывоз",
    params: {
      clientAddressRequired: false,
      deliveryCost: 0,
      minutesBeforeMinAvailableOrderTime: 60,
      minutesBetweenOrderTimeIntervals: 30,
      durationOfOneOrderTimeInterval: 30,
      timeTemplate: "h1:m1",
    },
  },
  deliveryTypes: [
    {
      id: 1,
      name: "Доставка",
      params: {
        clientAddressRequired: true,
        deliveryCost: 99,
        minutesBeforeMinAvailableOrderTime: 60,
        minutesBetweenOrderTimeIntervals: 30,
        durationOfOneOrderTimeInterval: 30,
        timeTemplate: "h1:m1 - h2:m2",
      },
    },
    {
      id: 2,
      name: "Самовывоз",
      params: {
        clientAddressRequired: false,
        deliveryCost: 0,
        minutesBeforeMinAvailableOrderTime: 20,
        minutesBetweenOrderTimeIntervals: 30,
        durationOfOneOrderTimeInterval: 10,
        timeTemplate: "h1:m1",
      },
    },
    {
      id: 3,
      name: "На месте",
      params: {
        clientAddressRequired: false,
        deliveryCost: 0,
        minutesBeforeMinAvailableOrderTime: 20,
        minutesBetweenOrderTimeIntervals: 30,
        durationOfOneOrderTimeInterval: 30,
        timeTemplate: "h1:m1",
      },
    },
  ],
  minutesFromStartWorkTimeToFirstAcceptedOrder: 20,
  minutesFromLastAcceptedOrderToFinishWorkTime: 60,
  deliveryTypeDefaultInView: 2,
  sendingOrderNow: false,
  receivedOrderDataFromApi: {},
};

export const getters: OrderGetters = {
  orderTimeForCalculateDeliveryTimes: (state, orderGetters, rS, rootGetters) => (time) => {
    let orderTime = time ? new Date(time) : new Date();
    const firstAcceptedOrderTimeToday: Date = rootGetters["settings/startWorkTimeOnDate"]();
    firstAcceptedOrderTimeToday.setMinutes(
      firstAcceptedOrderTimeToday.getMinutes() + state.minutesFromStartWorkTimeToFirstAcceptedOrder
    );

    if (orderTime <= firstAcceptedOrderTimeToday) {
      orderTime = firstAcceptedOrderTimeToday;
    }

    const getterName = "startTimeOfLastAvailableDeliveryTime";
    const lastAcceptedOrderTimeToday: Date = orderGetters[getterName](orderTime);

    if (orderTime > lastAcceptedOrderTimeToday) {
      firstAcceptedOrderTimeToday.setDate(firstAcceptedOrderTimeToday.getDate() + 1);
      orderTime = firstAcceptedOrderTimeToday;
    }

    return orderTime;
  },
  startTimeOfFirstAvailableDeliveryTime: (state) => (time, deliveryTypeId) => {
    const orderTime = time ? new Date(time) : new Date();
    let { minutesBeforeMinAvailableOrderTime } = state.defaultDeliveryType.params;

    if (deliveryTypeId) {
      const deliveryTypeData = state.deliveryTypes.find((elem) => elem.id === deliveryTypeId);
      if (deliveryTypeData) {
        minutesBeforeMinAvailableOrderTime =
          deliveryTypeData.params.minutesBeforeMinAvailableOrderTime;
      }
    }

    orderTime.setMinutes(orderTime.getMinutes() + minutesBeforeMinAvailableOrderTime);

    return orderTime;
  },
  startTimeOfLastAvailableDeliveryTime: (state, orderGetters, rootState, rootGetters) => (time) => {
    const orderTime = time ? new Date(time) : new Date();
    const result: Date = rootGetters["settings/finishWorkTimeOnDate"](orderTime);
    result.setMinutes(result.getMinutes() - state.minutesFromLastAcceptedOrderToFinishWorkTime);

    return result;
  },
  deliveryTimes: (state, orderGetters) => (deliveryTypeId, timeNow) => {
    const orderTime = timeNow ? new Date(timeNow) : new Date();

    let getterName = "orderTimeForCalculateDeliveryTimes";
    const orderTimeForCalc: Date = orderGetters[getterName](orderTime);

    getterName = "startTimeOfFirstAvailableDeliveryTime";
    const startOfFirstTimeItem: Date = orderGetters[getterName](orderTimeForCalc, deliveryTypeId);

    getterName = "startTimeOfLastAvailableDeliveryTime";
    const maxStartOfLastTimeItem: Date = orderGetters[getterName](orderTimeForCalc);

    let { minutesBetweenOrderTimeIntervals, durationOfOneOrderTimeInterval } =
      state.defaultDeliveryType.params;
    if (deliveryTypeId) {
      const deliveryTypeData = state.deliveryTypes.find((elem) => elem.id === deliveryTypeId);
      if (deliveryTypeData) {
        minutesBetweenOrderTimeIntervals = deliveryTypeData.params.minutesBetweenOrderTimeIntervals;
        durationOfOneOrderTimeInterval = deliveryTypeData.params.durationOfOneOrderTimeInterval;
      }
    }

    const result: Array<[Date, Date]> = [];

    const tempStartTime = startOfFirstTimeItem;
    const tempFinishTime: Date = new Date(tempStartTime);
    tempFinishTime.setMinutes(tempFinishTime.getMinutes() + durationOfOneOrderTimeInterval);

    while (tempStartTime <= maxStartOfLastTimeItem) {
      result.push([new Date(tempStartTime), new Date(tempFinishTime)]);

      tempStartTime.setMinutes(tempStartTime.getMinutes() + minutesBetweenOrderTimeIntervals);
      tempFinishTime.setMinutes(tempFinishTime.getMinutes() + minutesBetweenOrderTimeIntervals);
    }

    return result;
  },
  deliveryTimesWithTemplate: (state, orderGetters) => (deliveryTypeId, timeNow) => {
    let getterName = "deliveryTimes";
    const timeArray: Array<[Date, Date]> = orderGetters[getterName](deliveryTypeId, timeNow);

    let template = state.defaultDeliveryType.params.timeTemplate;
    if (deliveryTypeId) {
      const deliveryTypeData = state.deliveryTypes.find((elem) => elem.id === deliveryTypeId);
      if (deliveryTypeData) {
        template = deliveryTypeData.params.timeTemplate;
      }
    }

    const result: Array<{ time: [Date, Date]; template: string }> = [];

    getterName = "replaceValuesInPattern";

    timeArray.forEach((interval: [Date, Date]) => {
      const [start, finish] = interval;
      result.push({
        time: [new Date(start), new Date(finish)],
        template: orderGetters[getterName](template, interval),
      });
    });

    return result;
  },
  replaceValuesInPattern: () => (pattern, interval) => {
    const [start, finish] = interval;
    const replacements = [
      {
        pattern: "h1",
        value: (start.getHours() < 10 ? "0" : "") + start.getHours().toString(),
      },
      {
        pattern: "m1",
        value: (start.getMinutes() < 10 ? "0" : "") + start.getMinutes().toString(),
      },
      {
        pattern: "h2",
        value: (finish.getHours() < 10 ? "0" : "") + finish.getHours().toString(),
      },
      {
        pattern: "m2",
        value: (finish.getMinutes() < 10 ? "0" : "") + finish.getMinutes().toString(),
      },
    ];

    let result = pattern;

    replacements.forEach((item) => {
      result = result.replaceAll(item.pattern, item.value);
    });

    return result;
  },

  deliveryTypes: (state) => {
    const result: DeliveryTypesForComponent = {};

    state.deliveryTypes.forEach((item: DeliveryTypeItem) => {
      result[item.id.toString()] = {
        name: item.name,
        params: { clientAddressRequired: item.params.clientAddressRequired },
      };
    });

    return result;
  },

  deliveryTypeDefaultInView: (state) => state.deliveryTypeDefaultInView,

  deliveryCost: (state) => (deliveryTypeId) => {
    // TODO: write actual calculate
    let cost = state.defaultDeliveryType.params.deliveryCost;

    if (deliveryTypeId) {
      const deliveryTypeData = state.deliveryTypes.find((elem) => elem.id === deliveryTypeId);
      if (deliveryTypeData) {
        cost = deliveryTypeData.params.deliveryCost;
      }
    }

    return cost;
  },

  orderCost: (state, orderGetters, rootState, rootGetters) => (deliveryTypeId) => {
    let result = 0;

    result += +rootGetters["basket/basketCost"];

    const getterName = "deliveryCost";
    result += +orderGetters[getterName](deliveryTypeId);

    return result;
  },

  validateOrderData: (state) => (orderData) => {
    const returnedData: ValidatorResult = {
      result: true,
      error: "",
    };

    let clientAddressRequired = false;
    if (orderData.deliveryType) {
      const deliveryTypeData = state.deliveryTypes.find(
        (elem) => elem.id === orderData.deliveryType
      );

      if (deliveryTypeData && deliveryTypeData.params.clientAddressRequired) {
        clientAddressRequired = true;
      }
    }

    if (orderData.positions.length === 0) {
      returnedData.error = "Корзина пуста";
    } else if (!orderData.clientName || !orderData.clientPhone) {
      returnedData.error = "Не заполнены имя или номер телефона";
    } else if (
      !/^((8|\+?7)[-\s]?)?(\(?\d{3,4}\)?[-\s]?)?[\d\-\s]{5,10}$/.test(
        orderData.clientPhone.replaceAll(/\s+/g, "")
      )
    ) {
      returnedData.error = "Введён неверный номер телефона";
    } else if (clientAddressRequired && !orderData.address.length) {
      returnedData.error = "Не заполнен адрес доставки";
    } else if (!orderData.deliveryTime || orderData.deliveryTime <= new Date().getTime()) {
      returnedData.error = "Неверно указано время";
    }

    if (returnedData.error.length) {
      returnedData.result = false;
    }

    return returnedData;
  },
};

const mutations: OrderMutations = {
  changeSendingOrderNow: (state, sendingStatus) => {
    state.sendingOrderNow = sendingStatus;
  },

  saveOrderDataFromApi: (state, data) => {
    state.receivedOrderDataFromApi = { ...data };
  },
};

const actions: OrderActions = {
  async sendOrderToApi({ commit, rootState }, orderData) {
    const result: SendOrderToApiResult = {
      result: false,
      error: "",
      data: {},
    };

    try {
      commit("changeSendingOrderNow", true);
      const data = await rootState.api.sendOrder(orderData);
      commit("changeSendingOrderNow", false);
      commit("saveOrderDataFromApi", data);
      result.result = true;
      commit("basket/clearBasket", null, { root: true });
    } catch (e) {
      // e
      // TODO:
      result.error = "Ошибка отправки заказа. Попробуйте позже или свяжитесь с нами.";
    }

    return result;
  },
};

const order: Module<OrderState, RootState> = {
  namespaced: true,
  state: orderState,
  getters,
  mutations,
  actions,
};

export default order;
