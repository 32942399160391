
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapMutations } from "vuex";
import { SectionData } from "../interfaces/Section";
import { MealFullData, MealId } from "../interfaces/Meal";

@Options({
  computed: {
    ...mapGetters("sections", ["getAllSections"]),
    ...mapGetters("meals", ["getMealsBySectionId"]),
  },
  methods: mapMutations(["changeDisplayedMealId"]),
})
export default class Home extends Vue {
  getAllSections!: { [key: string]: SectionData };

  getMealsBySectionId!: (sectionId: number | string) => { [key: string]: MealFullData };

  changeDisplayedMealId!: (id: MealId) => void;

  openMealCard(mealId: MealId | string) {
    this.changeDisplayedMealId(+mealId);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}
